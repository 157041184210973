
































































































































































import { Component, Vue } from 'vue-property-decorator';
import { MapMarker, MapSettings, Franchise, Office, WebsiteLevel, BlogType, Blog } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';
import Map from '@/components/Map/Map.vue';

import { BlogFilter } from 'client-website-ts-library/filters';

@Component({
  components: {
    Map,
  },
})
export default class Footer extends Vue {
  public office: Office | null = null;

  public mapSettings: MapSettings | null = null;

  public suburbsFilter = new BlogFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.API.WebsiteId,
    Types: [BlogType.Suburb],
  });

  public suburbs: Blog[] = [];

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;

      this.mapSettings = new MapSettings({
        Markers: [
          new MapMarker({
            Address: `${this.office.AddressLine1} ${this.office.AddressLine2}`,
            Title: this.office.FranchiseName,
          }),
        ],
      });
    });

    API.Blogs.Search(this.suburbsFilter).then((data) => {
      this.suburbs = data.sort((a, b) => a.Title.localeCompare(b.Title));
    });
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }
}
